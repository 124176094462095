<template>
  <div class="page">
    <div class="banner">
      <img src="../../static/home/banner.png" class="banner-img" />
    </div>
    <div class="content">
      <div class="title">赋能美业未来 , 美蜂为美而生</div>
      <div class="driver"></div>
      <div class="write">
        美蜂专注于打造医生与用户之间零距离沟通的一站式医美消费平台,
        集中整合优质医疗整形专家资源，建立信息全面的医生数据库，
        搭建全链条一站式管家服务体系。
      </div>
      <div style="margin: 20px 0; width: 100%; text-align: center">
        <b-button class="more" variant="danger">了解我们</b-button>
      </div>

      <video
        class="video"
        src="../../static/home/banner.mp4"
        controls="controls"
        poster="../../static/home/poster.png"
      ></video>

    </div>
    <div class="news">
      <div class="ul">
        <div class="list-card">
          <img class="images" src="../../static/home/home_img_doctor.png" />
        </div>
        <div class="list-card" style="padding: 30px">
          <div style="list-top"></div>
          <div class="list-title" style="">好医生</div>
          <p class="span">
            严选1200+名合规合法机构医生，严苛标准把关准入门槛严防安全死角<br />
            所有入驻机构均经过6大项、26条标准严格审核合作医生均具备《医生执业证》和《医生资格证》，外籍医师必须具备《外国医师短期行医许可证》。
          </p>
        </div>
        <div class="list-card">
          <img class="images" src="../../static/home/home_img_service.png" />
        </div>
        <div class="list-card" style="padding: 30px">
          <div style="list-top"></div>
          <div class="list-title" style="">好机构</div>
          <p class="span">
            严选2000+合规合法机构，严苛标准把关准入门槛<br />
            所有入驻机构均经过6大项、26条标准严格审核 近8成5A机构在美蜂<br />
            年入驻审核率不超过10% 最早上线麻醉准入机制的平台
          </p>
        </div>
        <div class="list-card">
          <img class="images" src="../../static/home/home_img_mechanism.png" />
        </div>
        <div class="list-card" style="padding: 30px">
          <div style="list-top"></div>
          <div class="list-title" style="">好服务</div>
          <p class="span">
            800余名严选咨询师，提供1对1专业医美咨询服务。<br />
            通过术前咨询、术后恢复、机构面诊、到院进行项目执行，为顾客提供全流程服务。<br />
            提供高端会员尊享服务。
          </p>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="map-left">
        <div class="title">服务范围</div>
        <div class="driver"></div>
        <div class="desc">
          覆盖北京、上海、杭州、南京 深圳等全国30个城市，拥有在 线名医1200名，服务100000
          用户。
        </div>
        <div class="tags">
          <img src="../../static/home/home_label_city.png" class="tag" />
          <img src="../../static/home/home_label_service.png" class="tag" />
          <img src="../../static/home/home_label_user.png" class="tag" />
        </div>
      </div>
      <div class="map-right">
        <img src="../../static/home/home_img_map.png" class="map-img" />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
@import "./home.scss";
</style>
